<script lang="ts" setup>
defineProps<{
	showNumber?: boolean;
}>();
const { depositInfoData } = useDepositStreakData({ immediate: false });
</script>

<template>
	<div class="progress">
		<slot name="top" />
		<div class="progress-bar">
			<div
				v-for="(step, index) in depositInfoData.maxNumberOfDeposit || 8"
				:key="index"
				:class="[
					'step',
					{
						active: index < (depositInfoData.currentCountDeposit ?? 8)
					}
				]"
			>
				<AText v-if="showNumber" :modifiers="['bold']">
					{{ index + 1 }}
				</AText>
			</div>
		</div>
		<slot name="bottom" />
	</div>
</template>

<style scoped lang="scss">
$colors: (#ff6d23, #ff7b1f, #ff881a, #ff9715, #ffa610, #ffb30c, #ffc107, #ffd501);
.progress {
	width: max-content;
	border-radius: 20px;
	background: var(--changchun);
	padding: 10px;
	@include media-breakpoint-up(lg) {
		border-radius: 30px;
	}
	&-bar {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1px;
		.step {
			background-color: var(--craiova);
			padding: 4px 8px;
			line-height: 1;
			@for $i from 1 through length($colors) {
				&:nth-child(#{$i}) {
					color: nth($colors, $i);
				}
			}
			&.active {
				color: var(--craiova);
				@for $i from 1 through length($colors) {
					&:nth-child(#{$i}) {
						background: nth($colors, $i);
					}
				}
			}
			&:first-child {
				border-top-left-radius: 22px;
				border-bottom-left-radius: 22px;
			}
			&:last-child {
				border-top-right-radius: 22px;
				border-bottom-right-radius: 22px;
			}
		}
	}
}
</style>
